const Data = {
  pdf: {title:"备课课件", src:'//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/Koucai-L2-A-6-Beikei01.pdf'},
  word: {title:"超级口才-第六课-备课备注", src:'//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/Koucai-L2-A-6-Beikei02.pdf'},
  videos: [
    {
      title: '【说】学会分类表达',
      src: '//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/02kcl26.mp4'
    },
    {
      title: '【学·作品】贯口《报菜名》',
      src: '//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/04kcl26.mp4'
    },
    {
      title: '【学·表演】贯口《报菜名》',
      src: '//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/06kcl26.mp4'
    },
    {
      title: '【练】唇部力量',
      src: '//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/08kcl26.mp4'
    },
    {
      title: '【演】贯口《报菜名》',
      src: '//v001.1d1d100.com/zijing/beike/koucai/L2-A/06/10kcl26.mp4'
    }
  ]
};

export default Data;
