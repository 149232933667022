import React,{useEffect} from 'react';
import styled from 'styled-components';
import GlobalStyle from './__Global.style';
import Data from 'RootCommon/crm_h5_data';
const Main = styled.main`
background-color:#eee;
  max-width: 40rem;
  background: #f4f3f4;
  margin: 0 auto;
    .pdf{
      padding: 10px;
    display: flex;
    flex-direction: column;
    .tip{
      color:#aaa;
      font-size:1.2rem;
      .icon{
        line-height: 0.6;
        width:1.4rem;
        height:1.4rem;
        color:#bbb;
        padding:2px;
      }
    }
    a {
      margin: 15px 0;
      font-size:1.6rem;
      font-weight:bold;
    }
    }
  .videos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.8rem;
    h2 {
      font-size: 1.5rem;
      font-weight: 800;
      padding: 1rem 0;
    }
    video {
      margin-bottom: 1rem;
      width: 100%;
      object-fit:cover;
      border-radius:10px;
    }
  }
`;
const MIndex = () => {
  useEffect(() => {
    document.title="超级口才备课资料";
  }, [])
  return (
    <Main>
      <GlobalStyle />
      <section className="pdf">
        <a target="_blank" href={Data.word.src}>{Data.word.title} (点击查看)🔗</a>
        <a target="_blank" href={Data.pdf.src}>{Data.pdf.title} (点击查看)🔗</a>
      <h2 className="tip"><span className="icon">ℹ</span>建议微信内打开浏览</h2>
      </section>
      <hr/>
      <section className="videos">
        {Data.videos.map((v) => {
          return (
            <div key={v.title} className="video">
              <h2>👇{v.title}👇</h2>
              <video
                controls
                controlsList="nodownload"
                playsInline={true}
                webkit-playsinline="true"
                x5-video-player-type="h5"
                poster={`${v.src}?vframe/jpg/offset/1/w/500`}
                src={v.src}
              ></video>
            </div>
          );
        })}
      </section>
    </Main>
  );
};

export default MIndex;
