import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *{
    box-sizing:border-box;
    outline:none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgb(0,0,0,0);
    color:#2C2C2C;
  }
  html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size:20px;
    &.lock{
        overflow:hidden;
    }
  }
  body{
    -webkit-overflow-scrolling: touch;
    overflow:scroll;
    margin:0 auto;
    min-height:100vh;
    position: relative;
    background:#fff;
    font-weight:400;
    font-family:"PingFang SC", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial,
 "Hiragino Sans GB", "Source Han Sans", "Noto Sans CJK Sc", "Microsoft YaHei", "Microsoft Jhenghei", sans-serif;
    &.disableScroll{
        overflow:hidden;
    }

  }
  @media screen and (min-width: 320px){
      html {
          font-size: 9px;
      }
  }
   @media screen and (min-width: 375px){
      html {
          font-size: 10px;
      }
  }
   @media screen and (min-width: 411px){
      html {
          font-size: 11px;
      }
  }
   @media screen and (min-width: 480px){
      html {
          font-size: 12px;
      }
  }
  @media screen and (min-width: 750px){
      html {
          font-size: 24px;
      }
  } 
`;

export default GlobalStyle;
